import liff from '@line/liff/dist/lib';

type Account = {
  displayName: string;
  userId: string;
  pictureUrl?: string;
  statusMessage?: string;
};
const LINEACCOUNT: Account = {
  displayName: '',
  userId: '',
  pictureUrl: '',
  statusMessage: '',
};
const getProfile = async () => {
  liff.getAccessToken();
  const profile = await liff.getProfile();
  LINEACCOUNT.displayName = profile.displayName; //LINEの名前
  LINEACCOUNT.userId = profile.userId; //LINEのID
  LINEACCOUNT.pictureUrl = profile.pictureUrl; //LINEのアイコン画像
  LINEACCOUNT.statusMessage = profile.statusMessage; //LINEのステータスメッセージ
};
export const init = async () => {
  return liff.init({
    liffId: process.env.REACT_APP_LINE_APP_ID || '',
  });
};
export const login = async () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return;
  }
  await liff.init({
    liffId: process.env.REACT_APP_LINE_APP_ID || '',
  });

  if (liff.isInClient() || liff.isLoggedIn()) {
    return getProfile();
  } else {
    return liff.login();
  }
};

export const getUserId = () => {
  return LINEACCOUNT.userId;
};
