import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Providers from './Providers';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import Authenticated from './layouts/Authenticated';
import { init } from './lineID';
const TermsOfService = lazy(() => import('./pages/TermsOfServices'));
const Forms = lazy(() => import('./pages/Forms'));
const Page1 = lazy(() => import('./pages/Forms/Page1'));
const Page2 = lazy(() => import('./pages/Forms/Page2'));
const Page3 = lazy(() => import('./pages/Forms/Page3'));
const Page4 = lazy(() => import('./pages/Forms/Page4'));
const Page5 = lazy(() => import('./pages/Forms/Page5'));
const Flows = lazy(() => import('./pages/Flows'));
const About = lazy(() => import('./pages/About'));
const FAQs = lazy(() => import('./pages/FAQs'));
const FAQDetail = lazy(() => import('./pages/FAQs/FAQDetail'));
const Errors = lazy(() => import('./pages/Errors'));
const Guidance = lazy(() => import('./pages/Guidance'));

init().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Providers>
        <Suspense fallback={<> </>}>
          <Routes>
            <Route path="/flows" element={<Flows />} />
            <Route path="/about-insurance" element={<About />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/faqs/:id" element={<FAQDetail></FAQDetail>}></Route>
            <Route path="/error" element={<Errors />} />
            <Route path="/guidance" element={<Guidance />} />
            <Route path="/" element={<Authenticated />}>
              <Route path="" element={<Navigate to="/terms-of-service" />} />
              <Route path="terms-of-service" element={<TermsOfService />} />
              <Route path="forms" element={<Forms />}>
                <Route path="" element={<Navigate to="1" />} />
                <Route path="1" element={<Page1 />} />
                <Route path="2" element={<Page2 />} />
                <Route path="3" element={<Page3 />} />
                <Route path="4" element={<Page4 />} />
                <Route path="5" element={<Page5 />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </Providers>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(
  process.env.NODE_ENV === 'development' ? console.log : () => {}
);
